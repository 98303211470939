<template>
  <div class="cont-warp">
    <pub-header></pub-header>
    <div class="img-cont">
      <img src="../../../assets/images/securityTrain/h5/assess/pic01.png"/>
    </div>
    <div style="height: 60px"></div>
    <pub-footer></pub-footer>
  </div>
</template>

<script>
import PubFooter from "./pub/pubFooter";
import PubHeader from "./pub/pubHeader";

export default {
  name: '',
  components: {
    PubFooter,
    PubHeader
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace('/h5AssessPage');
    } else {
      this.$router.replace('/assessPage');
    }
  },
}
</script>
<style lang="less" scoped>
.img-cont {
  width: 95%;
  margin: 0 auto;
  margin-top: 60px;

  img {
    max-width: 100%;
    margin: 0px 0 30px 0;
  }
}

</style>
